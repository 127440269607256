import React, { ChangeEvent, useCallback, useState } from "react";
import { Navigate } from "react-router";
import { useAuthContext } from "../../api/useAuthContext";
import { useClient } from "../../api/useClient";
import { useTitle } from "../../utils/useTitle";

type RegForm = { login: string, password: string, passwordConfirm: string, steamId: string, payoutAddress?: string };

export const RegPage = React.memo(() => {

    useTitle('Registration');

    const client = useClient();
    const auth = useAuthContext();

    const [formData, setForm] = useState<RegForm>({ login: '', password: '', passwordConfirm: '', steamId: '', payoutAddress: '' });
    const [passwordEror, setPasswordError] = useState<null | string>(null);
    const handleFieldChange = useCallback((field: keyof RegForm) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setForm((prevValue) => {
                const newValue = { ...prevValue, ...{ [field]: event.target.value } };
                return newValue;
            });
        }
    }, [setForm]);

    const handleFormSubmit = useCallback((e) => {
        e.preventDefault();
        if (
            formData.login.length > 0
            && formData.password.length > 0
            && formData.passwordConfirm.length > 0
            && formData.steamId.length > 0
            && (formData.password === formData.passwordConfirm)) {
            client.mutateUserRegister({ input: formData })
                .then((result) => {
                    console.log('Register result', result);
                    if (result.userRegister.__typename === 'UserError') {
                        // TODO: Handle error                                        
                    } else if (result.userRegister.__typename === "UserToken") {
                        auth.auth({ token: result.userRegister.token, expireAt: parseInt(result.userRegister.expireAt) }, true);
                    }
                })
                .catch((e) => {
                    console.warn(e);
                });
        } else if (
            formData.login.length === 0
            || formData.steamId.length === 0
            || formData.password.length === 0
            || formData.passwordConfirm.length === 0) {
            setPasswordError('login, Steam ID and pasword cannot be empty')
        } else if (formData.password !== formData.passwordConfirm) {
            setPasswordError("Passwords don't match")
        }
    }, [client, formData, auth]);

    if (auth.isAuthed) {
        return (<Navigate to={'/user/profile'} />)
    }
    return (
        <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className='regForm'>
                <h4 className="regForm__title">
                    Let't get started
                </h4>
                <form onSubmit={handleFormSubmit} >
                    <div>
                        <input type={"text"} value={formData.login}
                            onChange={handleFieldChange('login')} placeholder='Enter your login' />
                        <input type={"text"} value={formData.steamId}
                            onChange={handleFieldChange('steamId')} placeholder='Enter your Sream ID' />
                        <input type={"text"} value={formData.payoutAddress}
                            onChange={handleFieldChange('payoutAddress')} placeholder='Enter your TON wallet' />
                        <input type={"password"} value={formData.password}
                            onInput={handleFieldChange('password')} placeholder='Enter your password' />
                        <input type={"password"} value={formData.passwordConfirm}
                            onInput={handleFieldChange('passwordConfirm')} placeholder='Repeat your pasword' />
                    </div>
                    {passwordEror && <div className='regForm__error'>{passwordEror}</div>}
                    <button type="submit" className='button regForm__btn'>
                        sign up free
                    </button>
                </form>
            </div>
        </div>
    );
});