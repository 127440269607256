import React from "react"

export const EventFormat = React.memo((props: {format: string, bet: number}) => {
	return (
		<div className="event__format">
			<h3 className="event__format-title">
				FORMAT
                      </h3>
			<div className="event__format-items">
				<div className="event__format-item">
					<div>GAME</div>
					<span>Dota 2</span>
				</div>
			
				<div className="event__format-item">
					<div>TEAM SIZE</div>
					<span>1vs1</span>
				</div>
				<div className="event__format-item">
					<div>PLAYER BET</div>
					<span>{props.bet}</span>
				</div>	
				<div className="event__format-item">
					<div>TOURNAMENT FORMAT</div>
					<span>{props.format}</span>
				</div>
			</div>
		</div>
	)
})