import React, { useState } from 'react'
import { useClient } from '../../api/useClient';

export const TableCell = React.memo((props: {
	player: string | null,
	team: string,
	// teams: LobbyTable,
	// setTeams: (v: LobbyTable) => void,
	callback: any,
	index: number
}) => {
	const [player, ] = useState<string | null>(props.player);

	const client = useClient();
	const login = client.useProfile().profile.login

	// useEffect(() => {
	// 	setPlayer(props.player)
	// }, [props.callback])

	return (
		<div
			className={player === null ? 'empty' : player === login ? 'occupied self' : 'occupied'}
			onClick={() => props.callback(props.team, props.index + 1, props.player)}
		>
			{player === null ? 'empty slot' : player}
		</div>
	)
})