import React from 'react'
import { Lobby_lobby } from '../api/spacex.types';
import { EventFormat } from '../components/EventFormat';
import { EventInfo } from '../components/EventInfo';
import { EventRules } from '../components/EventRules';

export const EventOverview = React.memo(({lobby}: {lobby: Lobby_lobby}) => {
	
	return (
		<div className="event__overview">
			<article className="event__overview-main">
				<EventFormat format={lobby.options.mode} bet={lobby.options.playerBet}/>
				<EventInfo />
				<EventRules />
			</article>
			<aside className="event__overview-aside">
				<div className="event__participants">
					<h4 className="event__participants-title">
						PARTICICPANTS
                      </h4>
					<div className="event__participants-inner">
						<ul>
							<li><div>Registered</div><span>{lobby.users.length}</span></li>							
						</ul>
					</div>
				</div>
			</aside>
		</div>
	)
})
