import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ChangeDotaLobbyTeam, DotaLobbyTeam } from '../api/spacex.types';
import { useClient } from '../api/useClient';
import { GameMode, LobbyPayer, LobbyTable } from '../utils/types';
import { TableCell } from './Table/TableCell';

export const EventBracket = React.memo(() => {

	let { id } = useParams<{ id: string }>();

	const client = useClient();
	const lobby = client.useLobby({ id: id as string });
	console.log(lobby.lobby.users, 'event lobbby');
	const login = client.useProfile().profile.login

	// let initLobby: LobbyTable = {
	// 	radiant: Array(GameMode.Team).fill(null),
	// 	dire: Array(GameMode.Team).fill(null)
	// }

	const [teams, setTeams] = useState<LobbyTable>(filterTeams(lobby.lobby.users))

	const handleChangeLobby = useCallback((team: string, slot: number, player: string | null) => {
		if (player === null) {
			client.mutateChangeDotaLobbyTeam({
				input: {
					team: team === 'radiant'
						? DotaLobbyTeam.RADIANT
						: team === 'dire'
							? DotaLobbyTeam.DIRE : DotaLobbyTeam.UNKNOWN,
					slot: slot
				},
				lobbyId: lobby.lobby.id
			})
				.then((result: ChangeDotaLobbyTeam) => {
					console.log(result.changeDotaLobbyTeam.users, 'lobby join');
					setTeams(filterTeams(result.changeDotaLobbyTeam.users))
					client.refetchLobby({ id: id as string });
				})
				.catch((e) => {
					console.warn(e);
				});
		} else if (player === login) {
			client.mutateChangeDotaLobbyTeam({
				input: {
					team: DotaLobbyTeam.UNKNOWN,
					slot: slot
				},
				lobbyId: lobby.lobby.id
			})
				.then((result: ChangeDotaLobbyTeam) => {
					console.log(result.changeDotaLobbyTeam.users, 'lobby leave');
					setTeams(filterTeams(result.changeDotaLobbyTeam.users))
					client.refetchLobby({ id: id as string });
				})
				.catch((e) => {
					console.warn(e);
				});
		}
	}, [client, lobby, setTeams, id, login]);

	function filterTeams(objects: LobbyPayer[]) {
		const radiant = Array(GameMode.Team).fill(null);
		const dire = Array(GameMode.Team).fill(null);

		for (let i = 0; i < objects.length; i++) {
			const obj = objects[i];
			if (obj !== null) {
				if (obj.params.team === 'radiant') {
					radiant.splice(obj.params.slot - 1, 1, obj)
				} else if (obj.params.team === 'dire') {
					dire.splice(obj.params.slot - 1, 1, obj)
				};
			}
		}
		console.log({ radiant: radiant, dire: dire }, 'filtered lobby');
		// setTeams({ radiant: radiant, dire: dire })
		return { radiant: radiant, dire: dire };
	}	
	useEffect(() => {
		setTeams(filterTeams(lobby.lobby.users))
		console.log(teams, 'teams');
	// eslint-disable-next-line
	}, [lobby])	
	useEffect(() => {
		setTeams(filterTeams(lobby.lobby.users))
		console.log(teams, 'callback');
	// eslint-disable-next-line
	}, [handleChangeLobby, lobby])

	return (
		<div className="event__bracket">
			<h3 className="event__format-title">
				BRACKET
            </h3>
			<div className="event__bracket-table">
				<div className="event__bracket-table-head">
					<div>Radiant</div>
					<div>Dire</div>
				</div>
				<div className="event__bracket-table-body">
					<div className="event__bracket-table-column">
						{teams.radiant.map((e, i) => {
							return (
								<TableCell
									key={`${i}-radiant`}
									player={e !== null && e.user !== null ? e.user.login : null}
									team={'radiant'}
									// teams={teams}
									// setTeams={setTeams}
									callback={handleChangeLobby}
									index={i}
								/>
							)
						})}
					</div>
					<div className="event__bracket-table-column">
						{teams.dire.map((e, i) => {
							return (
								<TableCell
									key={`${i}-dire`}
									player={e !== null && e.user !== null ? e.user.login : null}
									team={'dire'}
									// teams={teams}
									// setTeams={setTeams}
									callback={handleChangeLobby}
									index={i}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
})
