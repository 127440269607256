import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../api/useAuthContext';
import { ProfileLink } from '../routes/auth/ProfileLink';
import { Suspense } from '../utils/Suspense';



export const Header = React.memo(() => {

  const auth = useAuthContext();

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <Link to='/'>
            <img src='https://avatars.githubusercontent.com/u/94789130' style={{height: 50}} alt={"Logo"}/>
              </Link>
          {/* <ul className="header__menu">
            <li>
              <Link to="/">
                HOME
                  </Link>
            </li>
            <li>
              <Link to="/">
                CONTACTS
                  </Link>
            </li>
          </ul> */}
          <div className="login">
            {auth.isAuthed
              ? <Suspense><ProfileLink /></Suspense>
              : <Link to="/auth/login">
                LOGIN
                </Link>}
          </div>
        </div>
      </div>
    </header>
  );
});
