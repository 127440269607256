import * as React from 'react';
import App from './App';
import { AppProviders } from './AppProviders';

export const Boot = React.memo(() => {
    // Boot completed
    return (
        <AppProviders>
            {/* <BrowserRouter> */}
            <App />
            {/* </BrowserRouter> */}
        </AppProviders>
    );
});