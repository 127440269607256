import React, { useState } from "react";
import { useClient } from "../api/useClient";
import { TransactionComponent } from "./TransactionComponent";

export const TransactionsList = React.memo(() => {
    const client = useClient();    

    const [items, ] = useState(client.useTransactionList({ first: 100 }).transactionList);
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ul>
            {items.edges.length > 0 ?
                items.edges.map((edge, i) => {
                    return (
                            <TransactionComponent
                                key={`transaction-${edge.node.id}`}
                                transaction={edge.node}
                            />
                    )
                })
                : <li style={{textAlign:'center', fontSize:24}}>Transactions list is empty</li>
            }
            </ul>
        </div>
    );
})