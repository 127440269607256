import React from "react";
import { TransactionList_transactionList_edges_node, UserTransactionType } from "../api/spacex.types";

export const TransactionComponent = React.memo(({transaction}: { transaction: TransactionList_transactionList_edges_node }) => {

	return (
		<li>
			<div className='profile__transaction'>
				<div className="profile__transaction-top">
					<div className={`profile__transaction-operation ${transaction.type === UserTransactionType.ADD ? 'plus' : 'minus'}`}>
						operation: <span>{transaction.type === UserTransactionType.ADD? 'receipt' : 'charge-off'}</span>
					</div>
					<div className="profile__transaction-date">
						{transaction.createdAt}
					</div>
				</div>
				<div className="profile__transaction-sum">
					sum: <span>{transaction.amount}</span>
				</div>
			</div>
		</li>
	);
})