/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameList
// ====================================================

export interface GameList_gameList_edges_node {
  __typename: "Game";
  id: string;
  name: string;
  platform: GamePlatform;
  createdAt: any;
  updatedAt: any;
}

export interface GameList_gameList_edges {
  __typename: "GameEdge";
  node: GameList_gameList_edges_node;
  cursor: string;
}

export interface GameList_gameList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GameList_gameList {
  __typename: "GameConnection";
  edges: GameList_gameList_edges[];
  pageInfo: GameList_gameList_pageInfo;
}

export interface GameList {
  gameList: GameList_gameList;
}

export interface GameListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Game
// ====================================================

export interface Game_game {
  __typename: "Game";
  id: string;
  name: string;
  platform: GamePlatform;
  createdAt: any;
  updatedAt: any;
}

export interface Game {
  game: Game_game;
}

export interface GameVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LobbyList
// ====================================================

export interface LobbyList_lobbyList_edges_node_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface LobbyList_lobbyList_edges_node {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: LobbyList_lobbyList_edges_node_owner;
}

export interface LobbyList_lobbyList_edges {
  __typename: "LobbyEdge";
  node: LobbyList_lobbyList_edges_node;
  cursor: string;
}

export interface LobbyList_lobbyList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface LobbyList_lobbyList {
  __typename: "LobbyConnection";
  edges: LobbyList_lobbyList_edges[];
  pageInfo: LobbyList_lobbyList_pageInfo;
}

export interface LobbyList {
  lobbyList: LobbyList_lobbyList;
}

export interface LobbyListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Lobby
// ====================================================

export interface Lobby_lobby_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface Lobby_lobby_users_user {
  __typename: "User";
  id: string;
  login: string;
}

export interface Lobby_lobby_users {
  __typename: "LobbyUser";
  id: string;
  params: any;
  user: Lobby_lobby_users_user;
}

export interface Lobby_lobby {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: Lobby_lobby_owner;
  isReady: boolean;
  users: Lobby_lobby_users[];
}

export interface Lobby {
  lobby: Lobby_lobby;
}

export interface LobbyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLobby
// ====================================================

export interface CreateLobby_createLobby_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface CreateLobby_createLobby {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: CreateLobby_createLobby_owner;
}

export interface CreateLobby {
  createLobby: CreateLobby_createLobby;
}

export interface CreateLobbyVariables {
  input: CreateLobbyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: JoinLobby
// ====================================================

export interface JoinLobby_joinLobby_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface JoinLobby_joinLobby_users_user {
  __typename: "User";
  id: string;
  login: string;
}

export interface JoinLobby_joinLobby_users {
  __typename: "LobbyUser";
  id: string;
  params: any;
  user: JoinLobby_joinLobby_users_user;
}

export interface JoinLobby_joinLobby {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: JoinLobby_joinLobby_owner;
  users: JoinLobby_joinLobby_users[];
}

export interface JoinLobby {
  joinLobby: JoinLobby_joinLobby;
}

export interface JoinLobbyVariables {
  lobbyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeDotaLobbyTeam
// ====================================================

export interface ChangeDotaLobbyTeam_changeDotaLobbyTeam_users_user {
  __typename: "User";
  id: string;
  login: string;
}

export interface ChangeDotaLobbyTeam_changeDotaLobbyTeam_users {
  __typename: "LobbyUser";
  id: string;
  params: any;
  user: ChangeDotaLobbyTeam_changeDotaLobbyTeam_users_user;
}

export interface ChangeDotaLobbyTeam_changeDotaLobbyTeam {
  __typename: "Lobby";
  users: ChangeDotaLobbyTeam_changeDotaLobbyTeam_users[];
}

export interface ChangeDotaLobbyTeam {
  changeDotaLobbyTeam: ChangeDotaLobbyTeam_changeDotaLobbyTeam;
}

export interface ChangeDotaLobbyTeamVariables {
  lobbyId: string;
  input: ChangeDotaLobyTeam;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunLobby
// ====================================================

export interface RunLobby_runDotaLobby_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface RunLobby_runDotaLobby {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: RunLobby_runDotaLobby_owner;
}

export interface RunLobby {
  runDotaLobby: RunLobby_runDotaLobby;
}

export interface RunLobbyVariables {
  lobbyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LeaveLobby
// ====================================================

export interface LeaveLobby {
  leaveDotaLobby: boolean;
}

export interface LeaveLobbyVariables {
  lobbyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserLogin
// ====================================================

export interface UserLogin_userLogin_UserError {
  __typename: "UserError";
  messages: string[];
}

export interface UserLogin_userLogin_UserToken {
  __typename: "UserToken";
  token: string;
  expireAt: string;
}

export type UserLogin_userLogin = UserLogin_userLogin_UserError | UserLogin_userLogin_UserToken;

export interface UserLogin {
  userLogin: UserLogin_userLogin;
}

export interface UserLoginVariables {
  input: UserLoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserRegister
// ====================================================

export interface UserRegister_userRegister_UserError {
  __typename: "UserError";
  messages: string[];
}

export interface UserRegister_userRegister_UserToken {
  __typename: "UserToken";
  token: string;
  expireAt: string;
}

export type UserRegister_userRegister = UserRegister_userRegister_UserError | UserRegister_userRegister_UserToken;

export interface UserRegister {
  userRegister: UserRegister_userRegister;
}

export interface UserRegisterVariables {
  input: UserRegisterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Profile
// ====================================================

export interface Profile_profile_steamConnection {
  __typename: "SteamConnection";
  steamId: string;
}

export interface Profile_profile {
  __typename: "User";
  id: string;
  login: string;
  steamConnected: boolean;
  steamConnection: Profile_profile_steamConnection | null;
  payoutAddress: string | null;
  balance: number;
}

export interface Profile {
  profile: Profile_profile;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TransactionList
// ====================================================

export interface TransactionList_transactionList_edges_node {
  __typename: "UserTransaction";
  id: string;
  amount: number;
  createdAt: any;
  reason: string | null;
  type: UserTransactionType;
}

export interface TransactionList_transactionList_edges {
  __typename: "UserTransactionEdge";
  node: TransactionList_transactionList_edges_node;
  cursor: string;
}

export interface TransactionList_transactionList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface TransactionList_transactionList {
  __typename: "UserTransactionConnection";
  edges: TransactionList_transactionList_edges[];
  pageInfo: TransactionList_transactionList_pageInfo;
}

export interface TransactionList {
  transactionList: TransactionList_transactionList;
}

export interface TransactionListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AddCoinRequest
// ====================================================

export interface AddCoinRequest_addCoinRequest {
  __typename: "AddCoinRequest";
  address: string;
  comment: string;
}

export interface AddCoinRequest {
  addCoinRequest: AddCoinRequest_addCoinRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePayoutAddress
// ====================================================

export interface UpdatePayoutAddress_updatePayoutWalletAddress_steamConnection {
  __typename: "SteamConnection";
  steamId: string;
}

export interface UpdatePayoutAddress_updatePayoutWalletAddress {
  __typename: "User";
  id: string;
  login: string;
  steamConnected: boolean;
  steamConnection: UpdatePayoutAddress_updatePayoutWalletAddress_steamConnection | null;
}

export interface UpdatePayoutAddress {
  updatePayoutWalletAddress: UpdatePayoutAddress_updatePayoutWalletAddress;
}

export interface UpdatePayoutAddressVariables {
  address: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayoutList
// ====================================================

export interface PayoutList_payoutList_edges_node {
  __typename: "Payout";
  id: string;
  status: PayoutStatus;
  amount: number;
  createdAt: any;
  transferedAt: any | null;
  confirmedAt: any | null;
}

export interface PayoutList_payoutList_edges {
  __typename: "PayoutEdge";
  node: PayoutList_payoutList_edges_node;
  cursor: string;
}

export interface PayoutList_payoutList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface PayoutList_payoutList {
  __typename: "PayoutConnection";
  edges: PayoutList_payoutList_edges[];
  pageInfo: PayoutList_payoutList_pageInfo;
}

export interface PayoutList {
  payoutList: PayoutList_payoutList;
}

export interface PayoutListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IncomeList
// ====================================================

export interface IncomeList_incomeList_edges_node {
  __typename: "Income";
  id: string;
  createdAt: any;
  amount: number;
}

export interface IncomeList_incomeList_edges {
  __typename: "IncomeEdge";
  node: IncomeList_incomeList_edges_node;
  cursor: string;
}

export interface IncomeList_incomeList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface IncomeList_incomeList {
  __typename: "IncomeConnection";
  edges: IncomeList_incomeList_edges[];
  pageInfo: IncomeList_incomeList_pageInfo;
}

export interface IncomeList {
  incomeList: IncomeList_incomeList;
}

export interface IncomeListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePayout
// ====================================================

export interface CreatePayout_createPayout {
  __typename: "Payout";
  id: string;
  status: PayoutStatus;
  amount: number;
  createdAt: any;
  transferedAt: any | null;
  confirmedAt: any | null;
}

export interface CreatePayout {
  createPayout: CreatePayout_createPayout;
}

export interface CreatePayoutVariables {
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoFragment
// ====================================================

export interface PageInfoFragment {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserErrorFragment
// ====================================================

export interface UserErrorFragment {
  __typename: "UserError";
  messages: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserErrorHandler
// ====================================================

export interface UserErrorHandler {
  __typename: "UserError";
  messages: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GameFragment
// ====================================================

export interface GameFragment {
  __typename: "Game";
  id: string;
  name: string;
  platform: GamePlatform;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LobbyFragment
// ====================================================

export interface LobbyFragment_owner {
  __typename: "User";
  id: string;
  login: string;
}

export interface LobbyFragment {
  __typename: "Lobby";
  id: string;
  status: LobbyStatus;
  options: any;
  createdAt: any;
  updatedAt: any;
  owner: LobbyFragment_owner;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_steamConnection {
  __typename: "SteamConnection";
  steamId: string;
}

export interface UserFragment {
  __typename: "User";
  id: string;
  login: string;
  steamConnected: boolean;
  steamConnection: UserFragment_steamConnection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserTokenFragment
// ====================================================

export interface UserTokenFragment {
  __typename: "UserToken";
  token: string;
  expireAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PayoutFragment
// ====================================================

export interface PayoutFragment {
  __typename: "Payout";
  id: string;
  status: PayoutStatus;
  amount: number;
  createdAt: any;
  transferedAt: any | null;
  confirmedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IncomeFragment
// ====================================================

export interface IncomeFragment {
  __typename: "Income";
  id: string;
  createdAt: any;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserTransactionFragment
// ====================================================

export interface UserTransactionFragment {
  __typename: "UserTransaction";
  id: string;
  amount: number;
  createdAt: any;
  reason: string | null;
  type: UserTransactionType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DotaGameMode {
  MID_DUEL = "MID_DUEL",
}

export enum DotaLobbyTeam {
  DIRE = "DIRE",
  RADIANT = "RADIANT",
  UNKNOWN = "UNKNOWN",
}

export enum GamePlatform {
  STEAM = "STEAM",
}

export enum LobbyStatus {
  CREATED = "CREATED",
  FINISHED = "FINISHED",
  GAME_END = "GAME_END",
  GAME_RUN = "GAME_RUN",
  PROCESSING = "PROCESSING",
  READY = "READY",
  STARTING = "STARTING",
  WAIT_PLAYERS = "WAIT_PLAYERS",
}

export enum PayoutStatus {
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  TRANSFERED = "TRANSFERED",
}

export enum UserTransactionType {
  ADD = "ADD",
  CHARGE = "CHARGE",
}

export interface ChangeDotaLobyTeam {
  team: DotaLobbyTeam;
  slot?: number | null;
}

export interface CreateLobbyInput {
  game: string;
  mode: DotaGameMode;
  playerBet: number;
}

export interface UserLoginInput {
  login: string;
  password: string;
  recaptcha?: string | null;
}

export interface UserRegisterInput {
  login: string;
  password: string;
  passwordConfirm: string;
  steamId: string;
  payoutAddress?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
