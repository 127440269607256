import { QueryCacheProvider } from '@openland/spacex';
import * as React from 'react';

import { Suspense } from './Suspense';

export const WrapPage = React.memo((props: { children?: any }) => {
    return (
        <div style={{ position: 'relative', height: '100%', display: 'flex' }}>
            <QueryCacheProvider>
                <Suspense debug={false}>
                    <div style={{ position: 'relative', width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                        {props.children}
                    </div>
                </Suspense>
            </QueryCacheProvider>
        </div>
    )
})