import React, { ChangeEvent, useCallback, useState } from "react";
import { useClient } from "../api/useClient";

type FormData = { payoutAddress: string };

export const UpdatePayoutAddress = React.memo(() => {
    const client = useClient();
    const profile = client.useProfile().profile;
    const [formData, setForm] = useState<FormData>({ payoutAddress: profile.payoutAddress || '' });
    const handleFormSubmit = useCallback((e) => {
        e.preventDefault();
        if (formData.payoutAddress.length > 0) {
            client.mutateUpdatePayoutAddress({ address: formData.payoutAddress })
                .then((result) => {
                    console.log('Payout address updated', result);
                    client.refetchProfile();
                })
                .catch((e) => {
                    console.warn(e);
                });
        }
    }, [client, formData]);

    const handleFieldChange = useCallback((field: keyof FormData) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setForm((prevValue) => {
                const newValue = { ...prevValue, ...{ [field]: event.target.value } };
                return newValue;
            });
        }
    }, [setForm]);
// EQBc4gMsnxtxByLUZW3RGfV1cAszCoCec23L7A8RZDgIVqLH
    return (
        <div>
            <h2>Payout address</h2>
            <p>Your TON address for payouts</p>
            <form onSubmit={handleFormSubmit} >
                <div className='profile__input'>
                    <input type={"text"} value={formData.payoutAddress}
                        onChange={handleFieldChange('payoutAddress')} placeholder='Payout address' />
                        <button type="submit" className='button'>
                    update
                </button>
                </div>
                
            </form>
        </div>
    );
})