import React, { useMemo } from "react";
import { createClient } from "../api/createClient";
import { useAuthContext } from "../api/useAuthContext";
import { ClientContext, ClientContextType } from "../api/useClient";

export const ClientLoader = React.memo((props: { children?: any }) => {
    const auth = useAuthContext();


    const value: ClientContextType = useMemo(() => {
        console.log('ClientLoader: Token changed, recreate client');
        return {
            client: createClient(auth.token?.token),
        }
    }, [auth]);

    return (
        <ClientContext.Provider value={value}>
            {props.children}
        </ClientContext.Provider>
    );
});