import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthStateInterface, AuthToken } from "../api/useAuthContext";

const LOCAL_STORAGE_TOKEN_KEY = 'dtchmp.auth.token';

function loadLocalStorageToken(): AuthToken | undefined {
    let loadedKey: any = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || JSON.stringify({})) || {};
    return (loadedKey && typeof loadedKey.token === "string" && typeof loadedKey.expireAt === "number") ? loadedKey : undefined;
}

export const AuthLoader = React.memo((props: { children?: any }) => {
    const [token, setToken] = useState<AuthToken | undefined>(loadLocalStorageToken());

    const navigate = useNavigate();

    const auth = useCallback((token?: AuthToken, redirect?: boolean) => {
        const serializedToken = JSON.stringify(typeof token !== "undefined" ? token : null)
        console.log('AuthProvider: Update token', token, serializedToken);
        setToken(token);        
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, serializedToken);        
        if (redirect) {            
            console.log('AuthProvider: Navigate after auth', { pathname: '/user/profile' });
            navigate('/user/profile' );
        }
    }, [setToken, navigate]);

    const unAuth = useCallback(() => {
        console.log('AuthProvider: Logout');
        setToken(undefined);
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);        
        navigate('/auth/login' );
    }, [setToken, navigate]);

    const authContext = useMemo<AuthStateInterface>(() => {
        let isAuthed: boolean = false;
        if (typeof token !== "undefined" && !!token.token.trim()) {
            if (token.expireAt && token.expireAt > Date.now()) {
                isAuthed = true;
            } else {
                console.log('AuthProvider: Token has been expired', token.expireAt, Date.now());
            }
        } else {
            console.log('Token empty');
        }
        return {
            isAuthed,
            token,
            auth,
            unAuth
        }
    }, [token, auth, unAuth]);

    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    )
});