import moment from "moment"
import React from "react"
import { Link } from "react-router-dom"
import { LobbyList_lobbyList_edges_node, LobbyStatus } from "../api/spacex.types"

export const EventCard = React.memo(({lobby}: { lobby: LobbyList_lobbyList_edges_node }) => {
	const createdAt = moment(lobby.createdAt);

	return (
		<div className="tournaments__item">
			<div className="tournaments__item-img" style={{ backgroundImage: `url('https://cdn.dribbble.com/users/1381088/screenshots/5606559/media/97d1b81b336142c829cffd896af6b9de.png?compress=1&resize=300x225&vertical=top')` }}>
			</div>
			<div className="tournaments__item-info">
				<h3 className="tournaments__item-name">
					{lobby.id}
				</h3>
				<div className="tournaments__item-datetime">
					<div className='tournaments__item-time'>
						Starts in <span>{createdAt.format('lll')}</span>
					</div>			
				</div>
				<ul className="tournaments__item-bottom">
					<li>
						<div>Status</div>
						<span>{lobby.status}</span>
					</li>
					<li>
						<div>TEAM SIZE</div>
						<span>1 VS 1</span>
					</li>					
					<li>
						<div>SKILL LEVEL</div>
						<span>AMATEUR</span>
					</li>
					{[LobbyStatus.GAME_END, LobbyStatus.PROCESSING, LobbyStatus.FINISHED].includes(lobby.status) && 
					<li>
						<div>WINNER</div>
						<span>{lobby.options.result === 'dire_victory' ? 'DIRE' : 'RADIANT'}</span>
					</li>
					}
				</ul>
			</div>
			<div className="tournaments__item-prize">
				<span>PRIZE</span>
				<div className="tournaments__item-prize-money">
					{lobby.options.playerBet || 0} TON
				</div>
				<Link to={`/event/${lobby.id}`}>
					<button className="tournaments__item-prize-btn button">
						VIEW TOURNAMENT
					</button>
				</Link>
				<div>Winner get full prize</div>
			</div>
		</div>
	)
})