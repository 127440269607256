import * as React from 'react';
import { Link } from 'react-router-dom';
import { useClient } from '../../api/useClient';




export const ProfileLink = React.memo(() => {

	const client = useClient();
	const profile = client.useProfile().profile;

	return (
		<Link to="/user/profile">
			{profile.login}
		</Link>
	);
},(v,e)=>{return false});
