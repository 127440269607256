import React from "react"

export const EventRules = React.memo(() => {
	return (
		<div className="event__information">
			<h3 className="event__information-title">
				Rules
            </h3>
			<div className="event__information-wrapper">
				<ul className="event__information-list">
					<li>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque aspernatur eligendi asperiores at tempora ratione quisquam sapiente debitis iure cupiditate voluptatum, cumque, harum repudiandae praesentium, suscipit sit ipsum ut dicta.
                    </li>
					<li>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo minus facere atque quam? Necessitatibus, iure, architecto sunt voluptate perspiciatis ullam nihil hic eveniet magnam dolorem natus commodi, tenetur fugiat sint?
                    </li>
					<li>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus similique mollitia fuga harum ducimus? Quae ullam facere quisquam reprehenderit aperiam nemo aut, vero nisi pariatur sequi, ab voluptates obcaecati ratione.
                    </li>
					<li>
						Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem veritatis saepe at iusto velit? Minus, quaerat quisquam maxime excepturi aliquid, voluptatibus, dicta laboriosam corporis ullam autem omnis iusto perferendis doloribus.
                    </li>
					<li>
						Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reiciendis quaerat dicta asperiores minima cupiditate repellendus corrupti eaque corporis commodi, aliquam sapiente vel quo, in obcaecati sit ipsam ullam, accusantium veritatis!
                    </li>
				</ul>
			</div>
		</div>
	)
})