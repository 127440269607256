export enum GameMode {
	SoloMid = 1,
	Team = 5
}
export type User = {
	id: string,
	login: string,
}
export type LobbyPayer = {
	id: string,
	params: { slot: number, team: string },
	user: User
} | null
export type LobbyTable = {
	radiant: LobbyPayer[],
	dire: LobbyPayer[],
}