/* tslint:disable */
/* eslint-disable */
import * as Types from './spacex.types';
import { SpaceXClientParameters, GraphqlActiveSubscription, QueryParameters, MutationParameters, SubscriptionParameters, GraphqlSubscriptionHandler, BaseSpaceXClient, SpaceQueryWatchParameters } from '@openland/spacex';

export class ApiClient extends BaseSpaceXClient {
    constructor(params: SpaceXClientParameters) {
        super(params);
    }
    withParameters(params: Partial<SpaceXClientParameters>) {
        return new ApiClient({ ... params, engine: this.engine, globalCache: this.globalCache});
    }
    queryGameList(variables: Types.GameListVariables, params?: QueryParameters): Promise<Types.GameList> {
        return this.query('GameList', variables, params);
    }
    queryGame(variables: Types.GameVariables, params?: QueryParameters): Promise<Types.Game> {
        return this.query('Game', variables, params);
    }
    queryLobbyList(variables: Types.LobbyListVariables, params?: QueryParameters): Promise<Types.LobbyList> {
        return this.query('LobbyList', variables, params);
    }
    queryLobby(variables: Types.LobbyVariables, params?: QueryParameters): Promise<Types.Lobby> {
        return this.query('Lobby', variables, params);
    }
    queryProfile(params?: QueryParameters): Promise<Types.Profile> {
        return this.query('Profile', undefined, params);
    }
    queryTransactionList(variables: Types.TransactionListVariables, params?: QueryParameters): Promise<Types.TransactionList> {
        return this.query('TransactionList', variables, params);
    }
    queryAddCoinRequest(params?: QueryParameters): Promise<Types.AddCoinRequest> {
        return this.query('AddCoinRequest', undefined, params);
    }
    queryPayoutList(variables: Types.PayoutListVariables, params?: QueryParameters): Promise<Types.PayoutList> {
        return this.query('PayoutList', variables, params);
    }
    queryIncomeList(variables: Types.IncomeListVariables, params?: QueryParameters): Promise<Types.IncomeList> {
        return this.query('IncomeList', variables, params);
    }
    refetchGameList(variables: Types.GameListVariables, params?: QueryParameters): Promise<Types.GameList> {
        return this.refetch('GameList', variables, params);
    }
    refetchGame(variables: Types.GameVariables, params?: QueryParameters): Promise<Types.Game> {
        return this.refetch('Game', variables, params);
    }
    refetchLobbyList(variables: Types.LobbyListVariables, params?: QueryParameters): Promise<Types.LobbyList> {
        return this.refetch('LobbyList', variables, params);
    }
    refetchLobby(variables: Types.LobbyVariables, params?: QueryParameters): Promise<Types.Lobby> {
        return this.refetch('Lobby', variables, params);
    }
    refetchProfile(params?: QueryParameters): Promise<Types.Profile> {
        return this.refetch('Profile', undefined, params);
    }
    refetchTransactionList(variables: Types.TransactionListVariables, params?: QueryParameters): Promise<Types.TransactionList> {
        return this.refetch('TransactionList', variables, params);
    }
    refetchAddCoinRequest(params?: QueryParameters): Promise<Types.AddCoinRequest> {
        return this.refetch('AddCoinRequest', undefined, params);
    }
    refetchPayoutList(variables: Types.PayoutListVariables, params?: QueryParameters): Promise<Types.PayoutList> {
        return this.refetch('PayoutList', variables, params);
    }
    refetchIncomeList(variables: Types.IncomeListVariables, params?: QueryParameters): Promise<Types.IncomeList> {
        return this.refetch('IncomeList', variables, params);
    }
    updateGameList(variables: Types.GameListVariables, updater: (data: Types.GameList) => Types.GameList | null): Promise<boolean> {
        return this.updateQuery(updater, 'GameList', variables);
    }
    updateGame(variables: Types.GameVariables, updater: (data: Types.Game) => Types.Game | null): Promise<boolean> {
        return this.updateQuery(updater, 'Game', variables);
    }
    updateLobbyList(variables: Types.LobbyListVariables, updater: (data: Types.LobbyList) => Types.LobbyList | null): Promise<boolean> {
        return this.updateQuery(updater, 'LobbyList', variables);
    }
    updateLobby(variables: Types.LobbyVariables, updater: (data: Types.Lobby) => Types.Lobby | null): Promise<boolean> {
        return this.updateQuery(updater, 'Lobby', variables);
    }
    updateProfile(updater: (data: Types.Profile) => Types.Profile | null): Promise<boolean> {
        return this.updateQuery(updater, 'Profile', undefined);
    }
    updateTransactionList(variables: Types.TransactionListVariables, updater: (data: Types.TransactionList) => Types.TransactionList | null): Promise<boolean> {
        return this.updateQuery(updater, 'TransactionList', variables);
    }
    updateAddCoinRequest(updater: (data: Types.AddCoinRequest) => Types.AddCoinRequest | null): Promise<boolean> {
        return this.updateQuery(updater, 'AddCoinRequest', undefined);
    }
    updatePayoutList(variables: Types.PayoutListVariables, updater: (data: Types.PayoutList) => Types.PayoutList | null): Promise<boolean> {
        return this.updateQuery(updater, 'PayoutList', variables);
    }
    updateIncomeList(variables: Types.IncomeListVariables, updater: (data: Types.IncomeList) => Types.IncomeList | null): Promise<boolean> {
        return this.updateQuery(updater, 'IncomeList', variables);
    }
    useGameList(variables: Types.GameListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GameList | null;
    useGameList(variables: Types.GameListVariables, params?: SpaceQueryWatchParameters): Types.GameList;
    useGameList(variables: Types.GameListVariables, params?: SpaceQueryWatchParameters): Types.GameList | null {;
        return this.useQuery('GameList', variables, params);
    }
    useGame(variables: Types.GameVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Game | null;
    useGame(variables: Types.GameVariables, params?: SpaceQueryWatchParameters): Types.Game;
    useGame(variables: Types.GameVariables, params?: SpaceQueryWatchParameters): Types.Game | null {;
        return this.useQuery('Game', variables, params);
    }
    useLobbyList(variables: Types.LobbyListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.LobbyList | null;
    useLobbyList(variables: Types.LobbyListVariables, params?: SpaceQueryWatchParameters): Types.LobbyList;
    useLobbyList(variables: Types.LobbyListVariables, params?: SpaceQueryWatchParameters): Types.LobbyList | null {;
        return this.useQuery('LobbyList', variables, params);
    }
    useLobby(variables: Types.LobbyVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Lobby | null;
    useLobby(variables: Types.LobbyVariables, params?: SpaceQueryWatchParameters): Types.Lobby;
    useLobby(variables: Types.LobbyVariables, params?: SpaceQueryWatchParameters): Types.Lobby | null {;
        return this.useQuery('Lobby', variables, params);
    }
    useProfile(params: SpaceQueryWatchParameters & { suspense: false }): Types.Profile | null;
    useProfile(params?: SpaceQueryWatchParameters): Types.Profile;
    useProfile(params?: SpaceQueryWatchParameters): Types.Profile | null {;
        return this.useQuery('Profile', undefined, params);
    }
    useTransactionList(variables: Types.TransactionListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.TransactionList | null;
    useTransactionList(variables: Types.TransactionListVariables, params?: SpaceQueryWatchParameters): Types.TransactionList;
    useTransactionList(variables: Types.TransactionListVariables, params?: SpaceQueryWatchParameters): Types.TransactionList | null {;
        return this.useQuery('TransactionList', variables, params);
    }
    useAddCoinRequest(params: SpaceQueryWatchParameters & { suspense: false }): Types.AddCoinRequest | null;
    useAddCoinRequest(params?: SpaceQueryWatchParameters): Types.AddCoinRequest;
    useAddCoinRequest(params?: SpaceQueryWatchParameters): Types.AddCoinRequest | null {;
        return this.useQuery('AddCoinRequest', undefined, params);
    }
    usePayoutList(variables: Types.PayoutListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.PayoutList | null;
    usePayoutList(variables: Types.PayoutListVariables, params?: SpaceQueryWatchParameters): Types.PayoutList;
    usePayoutList(variables: Types.PayoutListVariables, params?: SpaceQueryWatchParameters): Types.PayoutList | null {;
        return this.useQuery('PayoutList', variables, params);
    }
    useIncomeList(variables: Types.IncomeListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.IncomeList | null;
    useIncomeList(variables: Types.IncomeListVariables, params?: SpaceQueryWatchParameters): Types.IncomeList;
    useIncomeList(variables: Types.IncomeListVariables, params?: SpaceQueryWatchParameters): Types.IncomeList | null {;
        return this.useQuery('IncomeList', variables, params);
    }
    mutateCreateLobby(variables: Types.CreateLobbyVariables, params?: MutationParameters): Promise<Types.CreateLobby> {
        return this.mutate('CreateLobby', variables, params)
    }
    mutateJoinLobby(variables: Types.JoinLobbyVariables, params?: MutationParameters): Promise<Types.JoinLobby> {
        return this.mutate('JoinLobby', variables, params)
    }
    mutateChangeDotaLobbyTeam(variables: Types.ChangeDotaLobbyTeamVariables, params?: MutationParameters): Promise<Types.ChangeDotaLobbyTeam> {
        return this.mutate('ChangeDotaLobbyTeam', variables, params)
    }
    mutateRunLobby(variables: Types.RunLobbyVariables, params?: MutationParameters): Promise<Types.RunLobby> {
        return this.mutate('RunLobby', variables, params)
    }
    mutateLeaveLobby(variables: Types.LeaveLobbyVariables, params?: MutationParameters): Promise<Types.LeaveLobby> {
        return this.mutate('LeaveLobby', variables, params)
    }
    mutateUserLogin(variables: Types.UserLoginVariables, params?: MutationParameters): Promise<Types.UserLogin> {
        return this.mutate('UserLogin', variables, params)
    }
    mutateUserRegister(variables: Types.UserRegisterVariables, params?: MutationParameters): Promise<Types.UserRegister> {
        return this.mutate('UserRegister', variables, params)
    }
    mutateUpdatePayoutAddress(variables: Types.UpdatePayoutAddressVariables, params?: MutationParameters): Promise<Types.UpdatePayoutAddress> {
        return this.mutate('UpdatePayoutAddress', variables, params)
    }
    mutateCreatePayout(variables: Types.CreatePayoutVariables, params?: MutationParameters): Promise<Types.CreatePayout> {
        return this.mutate('CreatePayout', variables, params)
    }
}
