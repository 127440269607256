import * as React from 'react';


// const style = StyleSheet.create({
//     layout: {
//         position: 'relative',
//         alignSelf: 'stretch',
//         flexGrow: 1,
//         flexShrink: 1,
//         alignItems: 'center',
//         justifyContent: 'center',
//         flexBasis: '100%',
//         marginVertical: 16,
//     }
// });

type Props = {
    style?: string
    debug?: boolean
}

export const Suspense = React.memo<React.PropsWithChildren<Props>>(props => {
    if (props.debug) {
        return <SuspenseLoader {...props} />
    }
    return (
        <React.Suspense fallback={<SuspenseLoader {...props} />}>
            {props.children}
        </React.Suspense>
    )
});


export const SuspenseLoader = React.memo<Props>(({ style: inputStyle }) => {    
    return (
        <div 
        // style={
        //     { backgroundColor: '#fff', position: 'relative',
        //     alignSelf: 'stretch',
        //     flexGrow: 1,
        //     flexShrink: 1,
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     flexBasis: '100%',
        //     margin: '16px 0', }
        // }
        >
            <p>Loading..</p>
        </div>
    );
});
