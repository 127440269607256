import moment from 'moment';
import React, { useCallback } from 'react'
import { Link, useParams } from 'react-router-dom';
import { JoinLobby, LeaveLobby, LobbyStatus } from '../api/spacex.types';
import { useAuthContext } from '../api/useAuthContext';
import { useClient } from '../api/useClient';
import { EventBracket } from '../components/EventBracket';
import { EventOverview } from '../components/EventOverview';
import { TabPanel } from '../components/TabComponent';
import { TabSelector } from '../components/Tabs';
import { LobbyPayer } from '../utils/types';
import { useTitle } from '../utils/useTitle';

export const EventPage = React.memo(() => {

  useTitle('Event')

  let { id } = useParams<{ id: string }>();

  const client = useClient();
  const profile = client.useProfile().profile
  const lobby = client.useLobby({ id: id as string }).lobby;
  const auth = useAuthContext()
  const createdAt = moment(lobby.createdAt);
  const [selectedTab, setSelectedTab] = React.useState<string>('overview');

  console.log(lobby, 'lobby params');

  const handleJoinLobby = useCallback(() => {
    client.mutateJoinLobby({ lobbyId: lobby.id })
      .then((result: JoinLobby) => {
        console.log('joined to lobby!', result.joinLobby);
      })
      .catch((e) => {
        console.warn(e);
      });

  }, [client, lobby]);

  const handleLeaveLobby = useCallback(() => {
    client.mutateLeaveLobby({ lobbyId: lobby.id })
      .then((result: LeaveLobby) => {
        console.log('leaved from lobby!', result.leaveDotaLobby);
      })
      .catch((e) => {
        console.warn(e);
      });

  }, [client, lobby]);

  const handleRunLobby = useCallback(() => {
    client.mutateRunLobby({ lobbyId: lobby.id }).then((result) => {
      client.refetchLobby({ id: result.runDotaLobby.id });
    }).catch(console.warn)
  }, [client, lobby]);

  function lobbyContainsUser(objects: LobbyPayer[]) {
    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      if (obj) {
        if (obj.user.id === profile.id) {
          console.log('lobby contains user');
          return true
        }
      }
    }
    console.log('user not found');
    return false
  }
  lobbyContainsUser(lobby.users)

  return (
    <div>
      <main>
        <section className="event">
          <div className="container">
            <div className="event__inner">
              <div className="event__info">
                <div className="event__info-inner">
                  <h2 className="event__title">
                    Dota 2 lobby: {lobby.id}
                  </h2>
                  <div className="event__datetime">
                    <div className='event-date'>
                      Status <span>{lobby.status}</span>
                    </div>
                    {lobby.status === LobbyStatus.CREATED && <div className='event-date'>
                      <span>Is {lobby.isReady ? 'ready' : 'not ready'}</span>
                    </div>}
                    {[LobbyStatus.GAME_END, LobbyStatus.PROCESSING, LobbyStatus.FINISHED].includes(lobby.status) &&
                      <div>
                        WINNER <span>{lobby.options.result === 'dire_victory' ? 'DIRE' : 'RADIANT'}</span>
                      </div>
                    }
                    <div className='event-time'>
                      Starts in <span>{createdAt.format('lll')}</span>
                    </div>
                  </div>
                </div>
                <div className="event__prize">
                  <span>{lobby.options.playerBet} TON</span>
                  {auth.isAuthed
                    ? <button
                      className={`button ${lobbyContainsUser(lobby.users) && 'negative'}`}
                      disabled={
                        lobby.owner.id === profile.id
                        // || lobbyContainsUser(lobby.users)
                      }
                      onClick={lobbyContainsUser(lobby.users)
                        ? handleLeaveLobby
                        : handleJoinLobby}>
                      {lobby.owner.id === profile.id
                        // || lobbyContainsUser(lobby.users)
                        ? 'you are in lobby'
                        : lobbyContainsUser(lobby.users) ? 'leave lobby' : 'join lobby'}
                    </button>
                    : <button className={'button'}>
                      <Link to="/auth/login">
                        LOGIN
                      </Link>
                    </button>
                  }
                  {
                    auth.isAuthed && (lobby.isReady && lobby.owner.id === profile.id) && (
                      <button
                        className='button'
                        onClick={handleRunLobby}
                      >
                        Run
                      </button>
                    )
                  }
                </div>
              </div>
              <div className="event__tabs">
                <TabSelector
                  title={'overview'}
                  isActive={selectedTab === 'overview'}
                  onClick={() => setSelectedTab('overview')}
                />
                <TabSelector
                  title={'bracket'}
                  isActive={selectedTab === 'bracket'}
                  onClick={() => {
                    auth.isAuthed &&
                      lobbyContainsUser(lobby.users) &&
                      setSelectedTab('bracket')
                  }}
                  disabled={!auth.isAuthed || !lobbyContainsUser(lobby.users)}
                />
                {/* <TabSelector
                  title={'matches'}
                  isActive={selectedTab === 'matches'}
                  onClick={() => setSelectedTab('matches')}
                />
                <TabSelector
                  title={'participants'}
                  isActive={selectedTab === 'participants'}
                  onClick={() => setSelectedTab('participants')}
                />
                <TabSelector
                  title={'prizes'}
                  isActive={selectedTab === 'prizes'}
                  onClick={() => setSelectedTab('prizes')}
                /> */}
              </div>
            </div>
            <div className="event__body">
              <div className="event__body-inner">
                {/* <EventOverview /> */}
                <TabPanel hidden={selectedTab !== 'overview'}>
                  <EventOverview
                    lobby={lobby}
                  />
                </TabPanel>
                <TabPanel hidden={selectedTab !== 'bracket'}>
                  <EventBracket />
                </TabPanel>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
})
