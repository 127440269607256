import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../api/useAuthContext'
import { CreateLobbyButton } from './user/CreateLobbyButton'
import { LobbyList } from './user/LobbyList'

export const HomePage = React.memo(() => {


  const auth = useAuthContext();

  // const eventsList = [
  //   {
  //     title: 'Tournament1'
  //   },
  //   {
  //     title: 'Tournament2'
  //   },
  //   {
  //     title: 'Tournament3'
  //   },
  //   {
  //     title: 'Tournament4'
  //   },
  //   {
  //     title: 'Tournament5'
  //   },
  //   {
  //     title: 'Tournament6'
  //   },
  //   {
  //     title: 'Tournament7'
  //   },
  // ]

  //   const handleFormSubmit = useCallback(() => {
  //     client.mutateUserLogin({ input: formData })
  //         .then((result) => {
  //             console.log('Login result', result);
  //             if (result.userLogin.__typename === 'UserError') {
  //                 // TODO: Handle error  
  //                 setLoginError('Incorrect login or password')
  //             } else if (result.userLogin.__typename === "UserToken") {
  //                 auth.auth({ token: result.userLogin.token, expireAt: parseInt(result.userLogin.expireAt) }, true);
  //                 setLoginError(null)
  //             }
  //         })
  //         .catch((e) => {
  //             console.warn(e);
  //         });

  // }, [client, formData, auth]);
  const navigate = useNavigate();
  return (
    <div>
      <main>
        <section className="descr">
          <div className="container">
            <div className="descr__inner">
              <div className="descr__title">
                play esports tournaments via your <span>crypto</span>assets
                </div>
            </div>
          </div>
        </section>
        <section className="tournaments">
          <div className="container">
            {/* <h3 className="tournaments__title">
              All events
              </h3> */}
            <div className="tournaments__items">
              {/* {eventsList.map((e: any, i: number) => {
                return <EventCard key={`${i}-eventCard`} title={e.title} />
              })} */}
              {auth.isAuthed
                ? <CreateLobbyButton />
                :
                <button className='button' onClick={() => {navigate('/auth/login')}}>                  
                    LOGIN                
                </button>
              }
              {auth.isAuthed && 
                <LobbyList />
              }
            </div>
          </div>
        </section>
      </main>
      <footer>

      </footer>
    </div>
  )
})
