import React, { useCallback, useState } from "react";
import { useClient } from "../api/useClient";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const AddCoinRequest = React.memo(() => {
    const client = useClient();

    const request = client.useAddCoinRequest().addCoinRequest;

    const [copied, setCopied] = useState<{ type?: 'address' | 'comment' }>({ type: undefined });
    const handleOnCopy = useCallback((type: 'address' | 'comment') => {
        return (text: string, result: boolean) => {
            setCopied({ type });
        }
    }, []);

    return (
        <div>
            <h2>Add coin to balance</h2>
            <p>To add coin please send TON to address bellow with specific comment</p>
            <p>&nbsp;</p>

            <p><b>Address</b></p>
            <div className='profile__input'>
                <input type={"text"} value={request.address}
                    placeholder='Wallet addrees' readOnly={true} />
                <CopyToClipboard text={request.address} onCopy={handleOnCopy('address')}>
                    <button className='button'>
                        {copied.type === 'address' ? 'copied' : 'copy'}
                    </button>
                </CopyToClipboard>

            </div>

            <p>&nbsp;</p>

            <p><b>Comment</b></p>
            <div className='profile__input'>
                <input type={"text"} value={request.comment}
                    placeholder='Comment' readOnly={true} />
                <CopyToClipboard text={request.comment} onCopy={handleOnCopy('comment')}>
                    <button className='button'>
                        {copied.type === 'comment' ? 'copied' : 'copy'}
                    </button>
                </CopyToClipboard>

            </div>

        </div>
    );
});