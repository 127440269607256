import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateLobby, DotaGameMode } from '../../api/spacex.types';
import { useClient } from '../../api/useClient';

export const CreateLobbyButton = React.memo(() => {
	const client = useClient();

	const navigate = useNavigate();

	const [bet, setBet] = useState<number | null>(null)

	const handleFieldChange = useCallback((event) => {
		setBet(parseFloat(event.target.value))

	}, [setBet]);

	console.log(bet, 'bet');
	const handleCreateLobby = useCallback(() => {
		client.mutateCreateLobby({ input: { game: 'dota2', mode: DotaGameMode.MID_DUEL, playerBet: bet || 0 } })
			.then((result: CreateLobby) => {
				console.log('lobby result', result.createLobby.id);
				navigate(`/event/${result.createLobby.id}`);
			})
			.catch((e) => {
				console.warn(e);
			});

	}, [client, navigate, bet]);

	return (
		<div className='tournaments__form'>
			<input placeholder="Enter the bet of your game (TON)" type="number" onChange={handleFieldChange} value={`${bet}`} />
			<button className='button'
			 disabled={bet === null ||isNaN(bet) || bet === 0}
			  onClick={handleCreateLobby}>CREATE LOBBY</button>
		</div>

	)
})
