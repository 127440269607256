import React, { ChangeEvent, useCallback, useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../api/useAuthContext";
import { useClient } from "../../api/useClient";
import { useTitle } from "../../utils/useTitle";

type LoginForm = { login: string, password: string, captcha?: string };

export const LoginPage = React.memo(() => {

    useTitle('Login');

    const client = useClient();
    const auth = useAuthContext();

    const [formData, setForm] = useState<LoginForm>({ login: '', password: '' });
    const [loginError, setLoginError] = useState<null | string>(null);
    const handleFieldChange = useCallback((field: keyof LoginForm) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setForm((prevValue) => {
                const newValue = { ...prevValue, ...{ [field]: event.target.value } };
                return newValue;
            });
        }
    }, [setForm]);

    const handleFormSubmit = useCallback((e) => {
        e.preventDefault();
        client.mutateUserLogin({ input: formData })
            .then((result) => {
                console.log('Login result', result);
                if (result.userLogin.__typename === 'UserError') {
                    // TODO: Handle error  
                    setLoginError('Incorrect login or password')
                } else if (result.userLogin.__typename === "UserToken") {
                    auth.auth({ token: result.userLogin.token, expireAt: parseInt(result.userLogin.expireAt) }, true);
                    setLoginError(null)
                }
            })
            .catch((e) => {
                console.warn(e);
            });

    }, [client, formData, auth]);

    if (auth.isAuthed) {
        return (<Navigate to={'/user/profile'} />)
    }
    return (
        <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className='regForm'>
                <h4 className="regForm__title">
                    Sign in
                </h4>
                <form onSubmit={handleFormSubmit} >
                    <div>
                        <input type={"text"} value={formData.login}
                            onChange={handleFieldChange('login')} placeholder='Enter your login' />
                        <input type={"password"} value={formData.password}
                            onInput={handleFieldChange('password')} placeholder='Enter your password' />
                    </div>
                    {loginError && <div className='regForm__error'>Incorrect login or password</div>}
                    <button type="submit" className='button regForm__btn'>
                        sign in
                    </button>
                </form>
                <div className='regForm__link'>Don't have account? <Link to="/auth/registration">Sign Up Here</Link></div>
            </div>
        </div>
    );
});