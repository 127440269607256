import React, { ChangeEvent, useCallback, useState } from "react";
import { useClient } from "../api/useClient";

type FormData = { amount: number };

export const CreatePayout = React.memo(() => {
    const client = useClient();
    const profile = client.useProfile().profile;
    const [formData, setForm] = useState<FormData>({ amount: 0 });
    const handleFormSubmit = useCallback((e) => {
        e.preventDefault();
        if (formData.amount > 0) {
            client.mutateCreatePayout({ amount: formData.amount })
                .then((result) => {
                    console.log('Payout address updated', result);
                    client.refetchProfile();
                })
                .catch((e) => {
                    console.warn(e);
                });
        }
    }, [client, formData]);

    const handleFieldChange = useCallback((field: keyof FormData) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setForm((prevValue) => {
                const newValue = { ...prevValue, ...{ [field]: parseFloat(event.target.value) } };
                console.log('New val', newValue);
                return newValue;
            });
        }
    }, [setForm]);

    return (
        <div>
            <h2>Create payout</h2>
            <p>Return <span>coins</span> from your balance to TON wallet</p>
            <form onSubmit={handleFormSubmit} >
                <div className='profile__input'>
                    <input type={"number"} value={formData.amount} max={profile.balance} min={0} step={0.01}
                        onChange={handleFieldChange('amount')} placeholder='Payout value' />
                    <button type="submit" className='button'>
                        Return
                </button>
                </div>

            </form>
        </div>
    );
})