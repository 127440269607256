import React from "react";

export interface AuthToken {
    token: string;
    expireAt: number;
}

export interface AuthStateInterface {
    readonly isAuthed: boolean;
    readonly token?: AuthToken;    

    auth(token: AuthToken, redirect?: boolean): void;
    unAuth(): void;
}

export const AuthContext = React.createContext<AuthStateInterface>(undefined as any);

export function useAuthContext() {
    return React.useContext(AuthContext);
}

