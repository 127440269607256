import * as React from 'react';
import { AuthLoader } from './loaders/AuthLoader';
import { ClientLoader } from './loaders/ClientLoader';
import { Suspense } from './utils/Suspense';
import { Header } from './components/Header';

export const AppProviders = React.memo((props: React.PropsWithChildren<any>) => {
    return (<>
         {/* <BrowserRouter> */}
            {/* <Routes> */}
                <AuthLoader>
                    <ClientLoader>
                        {/* <Route> */}
                        <div className={'wrapper'}>
                            <Header/>
                            <Suspense>
                                {props.children}
                            </Suspense>
                        </div>
                        {/* </Route> */}
                    </ClientLoader>
                </AuthLoader>
            {/* </Routes> */}
        {/* </BrowserRouter> */}
        </>
    )
});