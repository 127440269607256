import React from 'react'
import { LobbyList_lobbyList_edges } from '../../api/spacex.types';
import { useAuthContext } from '../../api/useAuthContext';
import { useClient } from '../../api/useClient';
import { EventCard } from '../../components/EventCard';


export const LobbyList = React.memo(() => {

	const client = useClient();
	const auth = useAuthContext();
	const lobbyList = client.useLobbyList({}).lobbyList.edges

	console.log(lobbyList, 'lobbylist');


	// const eventsList = [
	//   {
	//     title: 'Tournament1'
	//   },
	//   {
	//     title: 'Tournament2'
	//   },
	//   {
	//     title: 'Tournament3'
	//   },
	//   {
	//     title: 'Tournament4'
	//   },
	//   {
	//     title: 'Tournament5'
	//   },
	//   {
	//     title: 'Tournament6'
	//   },
	//   {
	//     title: 'Tournament7'
	//   },
	// ]

	//   const handleFormSubmit = useCallback(() => {
	//     client.mutateUserLogin({ input: formData })
	//         .then((result) => {
	//             console.log('Login result', result);
	//             if (result.userLogin.__typename === 'UserError') {
	//                 // TODO: Handle error  
	//                 setLoginError('Incorrect login or password')
	//             } else if (result.userLogin.__typename === "UserToken") {
	//                 auth.auth({ token: result.userLogin.token, expireAt: parseInt(result.userLogin.expireAt) }, true);
	//                 setLoginError(null)
	//             }
	//         })
	//         .catch((e) => {
	//             console.warn(e);
	//         });

	// }, [client, formData, auth]);

	return (
		<>
			<h3 className='tournaments__title'>List of your lobbys</h3>
			{auth.isAuthed &&
				lobbyList.map((e: LobbyList_lobbyList_edges, i: number) => {
					return <EventCard
						key={`${i}-eventCard`}
						lobby={e.node}											
						/>
				})}
		</>
	)
})
