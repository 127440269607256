import * as React from 'react';
import { getEndpoint } from '../api/getEndpoint';
import GraphiQL, { FetcherParams } from 'graphiql';
import 'graphiql/graphiql.css';

import { useAuthContext } from '../api/useAuthContext';

export const SandboxComponent = React.memo(() => {

    const auth = useAuthContext();

    async function graphQLFetcher(graphQLParams: FetcherParams) {    
        const response = await fetch(getEndpoint() + '/graphql', {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'x-dtchmp-token': graphQLParams.variables?.token || auth.token?.token },
            body: JSON.stringify(graphQLParams),
        });
        return await response.json();
    }

    return (
        <div style={{ alignSelf: 'stretch', height: '100vh', flexShrink: 1, flexGrow: 1 }}>
            <GraphiQL
                fetcher={graphQLFetcher}
            />
        </div>
    )
});