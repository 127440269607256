import * as React from 'react';
import { ApiClient } from './spacex';

export type ClientContextType = {
    client: ApiClient,    
}

export const ClientContext = React.createContext<ClientContextType>(undefined as any);

export function useClientContext() {
    return React.useContext(ClientContext);
}

export function useClient() {
    return useClientContext().client;
}

