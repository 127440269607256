import '../src/assets/styles/globals.scss';
import * as React from 'react';
import { SandboxComponent } from './dev/SandboxComponent';
import { Routes, Route, Navigate } from 'react-router-dom';
import { WrapPage } from './utils/wrapPage';
import { TestRoute } from './routes/TestRoute';
import { LoginPage } from './routes/auth/LoginPage';
import { ProfilePage } from './routes/user/ProfilePage';
import { useAuthContext } from './api/useAuthContext';
import { HomePage } from './routes/HomePage';
import { EventPage } from './routes/EventPage';
import { RegPage } from './routes/auth/RegPage';

export default React.memo(() => {
  //todo https://codesandbox.io/embed/jp1wr1867w
  // const location = useLocation()
  // const transitions = useTransition(location, location => location.pathname, {
  //     from: {opacity: 0, transform: 'translate3d(100%,0,0)'},
  //     enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
  //     leave: {opacity: 0, transform: 'translate3d(-50%,0,0)'},
  // })

  // Intercom.init();

  const auth = useAuthContext();
  console.log('ProtectedRoute: Auth context', auth);

  return (
    <>
      <Routes>
        <Route path="" element={<WrapPage><HomePage /></WrapPage>} />
        <Route path="/auth/registration" element={<WrapPage><RegPage /></WrapPage>} />
        <Route path={"/test"} element={<WrapPage><TestRoute /></WrapPage>} />
        <Route path={"/auth/login"} element={<WrapPage><LoginPage /></WrapPage>} />
        <Route path={"/event/:id"} element={auth.isAuthed
          ? <WrapPage><EventPage /></WrapPage>
          : <Navigate to={{ pathname: '/auth/login' }} />} />
        <Route path={"/dev"} element={<WrapPage><SandboxComponent /></WrapPage>} />
        <Route path={"/user/profile"} element={auth.isAuthed
          ? <WrapPage><ProfilePage /></WrapPage>
          : <Navigate to={{ pathname: '/auth/login' }} />} />
      </Routes>
    </>
  )

});