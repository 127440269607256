import React, { useCallback } from "react";
import { useAuthContext } from "../../api/useAuthContext";
import { useClient } from "../../api/useClient";
import { AddCoinRequest } from "../../components/AddCoinRequest";
import { CreatePayout } from "../../components/CreatePayout";
import { TabPanel } from "../../components/TabComponent";
import { TabSelector } from "../../components/Tabs";
import { TransactionsList } from "../../components/TransactionsList";
import { UpdatePayoutAddress } from "../../components/UpdatePayoutAddress";

export const ProfilePage = React.memo(() => {


    const client = useClient();
    const profile = client.useProfile().profile;
    const auth = useAuthContext();

    const [selectedTab, setSelectedTab] = React.useState<string>('info');

    const logOut = useCallback(() => {
        auth.unAuth()
    }, [auth]);
    console.log(profile, 'profile');

    // const list = [
    //     { operation: 'plus', sum: '0.001', date: '18.07.2000' },
    //     { operation: 'minus', sum: '0.003', date: '18.07.2000' },
    //     { operation: 'minus', sum: '10', date: '18.07.2700' },
    //     { operation: 'plus', sum: '20', date: '01.01.1970' },
    // ]


    return (
        <div className='profile'>
            <div className="container">
                <div className='profile__tabs'>
                    <TabSelector
                        title={'profile info'}
                        isActive={selectedTab === 'info'}
                        onClick={() => setSelectedTab('info')}
                    />
                    <TabSelector
                        title={'transactions'}
                        isActive={selectedTab === 'list'}
                        onClick={() => setSelectedTab('list')}
                        disabled={!auth.isAuthed}
                    />
                </div>
                <TabPanel hidden={selectedTab !== 'info'}>
                    <div className='profile__inner'>
                        <div className="profile__user">
                            <div>id: {profile.id}</div>
                            <div>login: {profile.login}</div>
                            <div>wallet: {profile.payoutAddress ? profile.payoutAddress : 'not connected'} </div>
                            <div>balance: <span>{profile.balance}</span></div>
                            <div>steam is {profile.steamConnected ? 'connected' : 'not connected'} </div>
                            <div>steam ID: {profile.steamConnection?.steamId} </div>
                            <button className='button' onClick={logOut}>logout</button>
                        </div>
                        <div className="profile__pay">
                            <AddCoinRequest />
                        </div>
                        <div className="profile__pay">
                            <CreatePayout />
                        </div>
                        <div className="profile__pay">
                            <UpdatePayoutAddress />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel hidden={selectedTab !== 'list'}>
                    <TransactionsList />
                </TabPanel>
            </div>
        </div>
    );
})