

import React from 'react'

export const TabSelector = React.memo((props: { isActive: boolean, onClick: () => void, title: string, disabled?: boolean }) => {
	return (
		<div className={props.disabled ? "event__tab disabled" : "event__tab"} >
			<span onClick={props.onClick} className={props.isActive ? 'active' : ''}>{props.title}</span>
		</div>
	)
})
